
:root {
  --main-color: #1B972B;
  --header-selected-font-size:14px;
  --header-font-size:16px;
  --dropdown-font-size:12px;
  --text-color:rgb(68, 68, 68);
}
.App {

}
@font-face {
  font-family: 'BalooTamma2';
  src: url('fonts/BalooTamma2-Medium.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Bangers';
  src: url('fonts/Bangers-Regular.ttf'); /* IE9 Compat Modes */
}
.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.newsPagesItem{
  border-bottom-width:0px;
  border-bottom-color: var(--main-color);
  border-bottom-style: solid;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.newsPagesItem{

}
.sideBar{
  display: block;
}
.articalsDiv{
  margin-left: 30%;
}
#HomePageContent{
  z-index: 3;
  width: 100%;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .footerCover{
    height: 80px !important;
  }

  .sideBar{
    display: none !important;
  }
  .newsPagesItem{

  }
  .articalsDiv{
    margin-left: 0!important;
  }
  .spliter{
    display: none !important;
  }
  .newsPagesItem{
    border-bottom-width: 0 !important;
    width: 90% !important;
    margin: auto;
  }
  #whatwedoDrop,#whoweareDrop,#newsDrop  {
    width: 100%;

  }
  .headerDropLink{
    font-family: BalooTamma2 !important;
}

  #FooterDiv{
    display: none;
  }
  #FooterDivHidden{
    display: none;
  }
  #FooterMobileDiv{
    display: block !important;
  }
  #footerTitle{
    padding-top: unset !important;;
  }
  #footerAddress{
    padding-top: unset !important;
  }

  #footerTitle p{
    margin-left: 20px;
    margin-right: 20px;
  }
  #seedsLogo{
    width: 100px;
  }
  #pageContent{
    width: 100%;
  }
  #HomePageContent{

        z-index: 1;
    width: 100%;
  }
  #homePage{

      background-color: #efefef;
      background-size: cover;
      position: relative;
      z-index: 1 !important;
    width: 100%;
  }
  #headerWrapScroll #seedsLogo{
    width: 60px;
  }
  #volImg,#donImg{
    width: 100%;
  }

}
.gallerySelectedImg{
  height: 400px;
}
@media only screen and (max-width: 670px){
  #factsDiv{
    padding-top: 30px !important;
  }
  .galleryImgDiv{
    width: 33.3333% !important;
  }
  .gallerySelectedImg{
    height: 200px !important;
  }
  #factItems{
    width: 90% !important;
    margin-top: 0 !important;
  }
  .columnSpiter{
    display: none !important;
  }
  .newsText{
    font-size: 14px !important;
    color: #2B235E !important;
    font-family: BalooTamma2 !important;
  }
  .newsText *{
    font-size: 14px !important;
    color: #2B235E !important;
    font-family: BalooTamma2 !important;
  }
  .newsImage{
    width: 100%;
    height: 150px !important;
    vertical-align: middle;
    padding-top: 60px;

    object-fit: cover;
  }
  .centerNewImg{
    transform: scale(0.9) !important;
  }
  .newsPagesItem{
    border-bottom-width: 0px !important;
    width: 100% !important;
  }
  .tabItem img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
  .teamItem img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
  .teamItem{
    margin-left: 0px !important;
  }
  #voleBtn,#donBtn{
    right: 30% !important;
    width: 130px !important;
    font-size: 16px !important;
   }
  #volImg,#donImg{
    height: 300px !important;
  }
  #headerWrap{

  }
}
#headerWrap{
  background-color: #e7e7e7;
  position: fixed;
  top: 0;
  padding-left: 3%;
  padding-right: 3%;
  z-index: 100;
  width: 100%;
  transition-timing-function: linear;
  -webkit-transition: height 0.5s; /* For Safari 3.1 to 6.0 */
  transition: height 0.5s;
  height: 150px;
}
#headerWrapScroll{
  background-color: #e7e7e7;
  position: fixed;
  width: 100%;
  top:0;
  z-index: 10;
  transition-timing-function: linear;
  -webkit-transition: height 0.5s; /* For Safari 3.1 to 6.0 */
  transition: height 0.5s;
}
#seedsLogo{

  object-fit: contain;
}
#seedsLogoScroll{
  width: 200px;
  height: 90px;
}
.dropbtn:focus{
  box-shadow:0 0 0 0 rgba(0,123,255,.25);
  outline: none;
}
.navbar-nav{
    margin-left: 4%;
}
#linksDiv{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  margin-left: 250px;
}
#homeBtn{
  color: var(--main-color);
  margin-right: 5px;
  font-family: "Sugoe UI";
  font-size: var(--header-selected-font-size);
  text-align: center;
  text-decoration: none;
  font-weight: bold;
}
.headerLinks{



}
.dropdown{

}
#pageContent{
  padding-top: 150px;
  background-color: #efefef;
  width: 100%;
  height: 100%;
  min-height: 1000px;
  z-index: 5;
  position: relative;
}
.texttt{
  color: black;
}

.dropbtn {
  width: 100%;
  background-color: transparent;
  font-family: "BalooTamma2";
  font-weight: bold;
   color: #2B235E;
  font-size: var(--header-font-size);
  padding: 16px;
  border: none;
  transition-timing-function: linear;
  -webkit-transition: background-color 1s; /* For Safari 3.1 to 6.0 */
  transition: background-color 1s;
}
.dropbtnSelected{
  color: var(--main-color) !important;
    background-color: transparent !important;
}
.dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
}

#whatwedoDrop{
  min-width: 170px;
}
#whoweareDrop{

  min-width: 170px;
}
.headItemAr{
  margin-top: 50px;
}
#newsDrop{
  min-width: 156px;
}
.dropdown-content a {
  color: #2B235E;
  padding: 12px 16px;
  font-size: var(--dropdown-font-size);
  font-family: BalooTamma2;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  display: block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #EEEEEE;


}
.dropdown-content a:hover {
  background-color: #2B235E;
  color:#fff;
  transition-timing-function: linear;
  -webkit-transition: color 0.5s,background-color 1s; /* For Safari 3.1 to 6.0 */
  transition: color 0.5s,background-color 1s;
}

.dropdown:hover .dropdown-content {
  display: block;
  z-index: 1;


}
.dropdown:hover .dropbtn {
  background-color: #EEEEEE;
  transition-timing-function: linear;
  -webkit-transition: background-color 1s; /* For Safari 3.1 to 6.0 */
  transition: background-color 1s;
}
.dropbtn:hover{
  background-color: #EEEEEE;
  transition-timing-function: linear;
  -webkit-transition: background-color 1s; /* For Safari 3.1 to 6.0 */
  transition: background-color 1s;
}
#contactBtn{
  width: 100%;
  font-family: "BalooTamma2";
  font-weight: bold;
  color: #2B235E;
  font-size: var(--header-font-size);
  padding: 16px;
  border: none;
  text-decoration: none;
  background-color: transparent;
}
#contactBtn:hover{
  background-color: #EEEEEE;
}

#donateBtn{
  width: 100%;
  font-family: "BalooTamma2";
  font-weight: bold;
  color: white;
  font-size: var(--header-font-size);
  padding: 16px;
  border: none;
  text-decoration: none;
  background-color:#2B235E;
  transition-timing-function: linear;
  -webkit-transition: background-color 2s,color 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s;
}

#sacrificesBtn{
  background-color: #1b971b !important;
  width: 100%;
  font-family: "BalooTamma2";
  font-weight: bold;
  color: white;
  font-size: var(--header-font-size);
  padding: 16px;
  border: none;
  text-decoration: none;
  transition-timing-function: linear;
  -webkit-transition: background-color 2s,color 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s;
}
#volBtn{
  width: 100%;
  font-family: "BalooTamma2";
  font-weight: bold;
  color: white;
  font-size: var(--header-font-size);
  padding: 16px;
  border: none;
  text-decoration: none;
  background-color:#9C2062 ;
  transition-timing-function: linear;
  -webkit-transition: background-color 2s,color 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s;
}
#donateBtn:hover{
  background-color: #EEEEEE;
  color: var(--main-color);
  transition-timing-function: linear;
  -webkit-transition: background-color 2s,color 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s;
}
#volBtn:hover{
  background-color: #EEEEEE;
  color: #9C2062;
  transition-timing-function: linear;
  -webkit-transition: background-color 2s,color 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s;
}
#socialIcons{
  padding-left: 20px;
  width: 200px;
  margin: auto;
}
.socialIconImg{
  vertical-align: baseline;
  margin-top: 7px;

}
#twicon{
  margin-left: 10px;
}
#twiconAr{
  margin-right: 10px;
}
#langSelection{

}
#splitLine{
  width: 80px;
  display: block;
  margin: auto;
}
.langBtn{
  display: block;
  font-family: "Sugoe UI";
  color: black;
  font-weight: bold;
  margin: auto;
  text-align: center;
  transition-timing-function: linear;
  -webkit-transition: color 1s; /* For Safari 3.1 to 6.0 */
  transition: color 1s;
}
.langBtnSelected{
  display: block;
  font-family: "Sugoe UI";
  color: #9C2062;
  font-weight: bold;
  margin: auto;
  text-align: center;
  transition-timing-function: linear;
  -webkit-transition: color 1s; /* For Safari 3.1 to 6.0 */
  transition: color 1s;
}
.langBtn:hover{
  color: var(--main-color);
  text-decoration: none;
  transition-timing-function: linear;
  -webkit-transition: color 1s; /* For Safari 3.1 to 6.0 */
  transition: color 1s;
}
.langBtn:hover ~ .langBtnSelected{
  color: black;
  transition-timing-function: linear;
  -webkit-transition: color 1s; /* For Safari 3.1 to 6.0 */
  transition: color 1s;
}
.langBtnSelected:hover{
  color: var(--main-color);
  text-decoration: none;
}
#volImg,#donImg{
  object-fit: cover;
  width: 100%;
  height: 550px;
}
.topImgDiv{
  width: 50%;
  display: inline-block;
  position: relative;
}
#donBtn{
  width: 180px;
  text-align: center;
  position: absolute;
  padding: 16px;
  background-color: var(--main-color);
  color: white;
  font-family: "Sugoe UI";
  font-weight: bold;
  bottom: 10%;
  right: 40%;
  font-size: 22px;
  transition-timing-function: linear;
  -webkit-transition: background-color 2s,color 2s,font-size 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s,font-size 2s;
}
#donBtn:hover{
  background-color: #EEEEEE;
  font-size: 24px;
  color: var(--main-color);
  transition-timing-function:ease;
  -webkit-transition: background-color 2s,color 2s,font-size 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s,font-size 2s;
  text-decoration: none;
}
#voleBtn{
  width: 180px;
  text-align: center;
  position: absolute;
  padding: 16px;
  background-color: black;
  color: white;
  font-family: "Sugoe UI";
  font-weight: bold;
  bottom: 10%;
  right: 40%;
  font-size: 22px;
  transition-timing-function:ease;
  -webkit-transition: background-color 2s,color 2s,font-size 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s,font-size 2s;
}
#voleBtn:hover{
  background-color: #EEEEEE;
  color: black;
  font-size: 24px;
  transition-timing-function:ease;
  -webkit-transition: background-color 2s,color 2s,font-size 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s,color 2s,font-size 2s;
  text-decoration: none;
}
.socialIcon{
  -webkit-transition:  -webkit-transform 2s; /* Safari */
  transition:  transform 2s;
  display: inline-block;
}

.newsCarousel{
  width: 100%;
}
.newsImage{
  width: 100%;
  height: 250px;
  vertical-align: middle;
  padding-top: 70px;

  object-fit: cover;
}
.centerNewImg{
  transform: scale(0.9);
}
#HomeNewBannerDiv{

  padding-top: 30px;
  z-index: 1;

}
.newsText{
  font-size: 19px;
  color: #2B235E;
  height: 10px;
  height: 30px;
  margin-top: 10px;
  text-align: center;
  margin: auto;
  margin-top: 12px;
  cursor: pointer;
  font-family: BalooTamma2 !important;
}
.newsText *{
  color: #2B235E;
  font-family: BalooTamma2 !important;
}
#donAndVolText{
  margin: auto;
  font-family: BalooTamma2 !important;
  width: 75%;
  text-align: center !important;
  overflow: hidden;
}
#subPageContent{
  padding-top: 30px;
  padding-left: 3%;
  padding-right: 3%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#homeSubPageContent{
  padding-top: 30px;
    z-index: 5;
    background-color: #efefef;
}
#missionDiv{
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  height: fit-content;
  width: 500px;
  margin: auto;
}
#missionDivMobile{
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  height: fit-content;
  width: 100%;
  margin: auto;
}
#HomeRoundedTabsDiv{
  padding-top: 25px;
  width: 1050px;
  margin: auto;
}
.HomeRoundedTabsDivMobile{
  width: 100% !important;
  margin: auto;
}
#tabsInner{

}
.tabItem{
  display: inline-block;

}

.tabItem img{
  width: 270px;
  height: 270px;
  border-radius: 270px;
  display: block;
  margin: auto;
  transition-timing-function: linear;
  -webkit-transition: filter 2s; /* For Safari 3.1 to 6.0 */
  transition: filter 2s;
  object-fit: cover;
  cursor: pointer;

}
.tabItem:hover img{
  filter:  brightness(40%);
  transition-timing-function: linear;
  -webkit-transition: filter 2s; /* For Safari 3.1 to 6.0 */
  transition: filter 2s;

}
.tabItem:hover p{
  color:var(--main-color);

}
.tabItem p{
  text-align: center;
  font-family: "Sugoe UI";
  font-weight: bold;
  font-size: 18px;
  color: var(--text-color);
  margin-top: 5px;
}
#factsDiv{
  width: 100%;
  height: 500px;
    background-image: url("imgs/facts4.jpg");
  background-size:cover;
    background-position: center 60%;
    background-repeat: no-repeat;
  padding-top: 100px;
}
#factsDiv h2{
  text-align: center;
  font-family: BalooTamma2;
  font-weight: bold;
  font-size: 40px;
  color:  #2B235E;
}
.factItem{
  display: inline-block;

}
.factItemNotFirst{
  margin-left: 40px;
}
.factItem h3{
  color: #9C2062;
  text-align: center;
  font-size: 55px;
  margin-bottom: 0;
  font-family: BalooTamma2;
}
.factItemNumber{
  color: #9C2062;
  text-align: center;
  font-size: 55px;
  margin-bottom: 0;
  font-family: BalooTamma2;
  margin: auto !important;
  width: 100% !important;
  height: 65px;
}
.factItem p{
  color:#2B235E;
  font-family: BalooTamma2;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}
#factItems{
  margin-top: 20px;
  width: 60%;
  margin: auto;
}
#bottomImage{
  width: 100%;
}
#FooterDivHidden{
  width: 100%;
  height: 0px;
}
#FooterDiv{

  position: fixed;
  bottom: 0;
  width: 100%;
  height: 300px;
  background-color: #2B235E;
  z-index: 0;
  padding-top: 50px;
  margin: 0!important;

}
#FooterMobileDiv{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding-left: 50px;
  display: none;
  background-color: #2B235E;
  z-index: -1;padding-top: 50px;
}
#footerContent{
  width: 100%;
  margin: auto;
}
#footerTitle h5{
  color: white;
  font-family: "BalooTamma2";
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  bottom: 5px;
}
.footerCol{
  position: relative;
  height: 200px;
}
#footerLinks{
  padding-top: 40px;
}
.footerLink{
  color: white;
  display: block;
  font-family: "BalooTamma2";
  font-weight: bold;
  font-size: 22px;
  width: fit-content;
}
#footerSocialLinks{
  padding-top: 30px;
}
.footerSocialLink{
  width: fit-content;
}
.footerSocialLink p{
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  font-size: 17px;
  font-family: "BalooTamma2";
  color: white;
  vertical-align: middle;
  margin-bottom: 0;
}
#footerDonate h3{
  margin-top: 46px;
  color: white;
  width: fit-content;
  font-family: "BalooTamma2";
  font-weight: bold;
  margin-right: 9%;
}
#footerDonateVal{
  position: absolute;
  top: 1px;
  left: 48%;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
}
#footerDonateValAr{
  position: absolute;
  top: 1px;
  font-size: 14px;
  font-weight: bold;
  right: 48%;

  color: var(--text-color);
}
#footerDonateBtn{
  width: 30%;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  color: #84c38e;
  font-family: "Sugoe UI";
  font-weight: bold;
  background-color: white;
}
#donateRange{
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    background: #d3c02c;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 5px;
}
#donateRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #e7e7e7;
    cursor: pointer;
    z-index: 3;
}

#donateRange:hover {
    opacity: 1;
}

#donateRange::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: white;
    cursor: pointer;
}
#footerAddress{
  padding-top: 10px;
  padding-bottom: 20px;
}
#footerAddress p{
  font-family: BalooTamma2;
  font-size: 14px;
  color: white;
  margin: 0;
  text-align: center;
  direction: ltr;
}
/* volunteerPage */
#volunteerIframe{
  width: 100%;
  height:500px;
  border:0px;
}
#volText{
  font-size: 19px;
  font-family: "Sugoe UI";
  color:var(--text-color)
}
#volFormDiv{
    display: inline-block;
}
#Volform{
  padding-top: 40px;
  margin: auto;

}
.volFormsAr{
  text-align: right !important;
  padding-right: 5px !important;
}
.volForms{
    min-width: 100px;
  font-family: "BalooTamma2";
  font-size: 15px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  color: var(--text-color);
  background-color: #F1F1F1;
  padding-left: 5px;
  border: var(--main-color);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  transition-timing-function:ease;
  -webkit-transition: border-width 0.5s; /* For Safari 3.1 to 6.0 */
  transition: border-width 0.5s;
  border-radius: 5px;
  height: 40px;
}
.volForms:focus{
  border-width: 2px 2px 2px 2px;
  outline: none;
  transition-timing-function:ease;
  -webkit-transition: border-width 0.5s; /* For Safari 3.1 to 6.0 */
  transition: border-width 0.5s;
}
.volFormsCheck{
  width: 100%;
  margin-bottom: 20px;
}
.volFormsCheck p{
  font-family: "BalooTamma2";
  font-size: 15px;
  color: #2B235E;
  font-weight: bold;


}
.formcheck{
  margin-left: 10px;
}
.formcheck label{
  color: #1B972B;
  font-family: "BalooTamma2";
  font-weight: bold;
  line-height: 2;
}
#submitBtn{
  font-family: "BalooTamma2";
  font-weight: bold;
  font-size: 17px;
  color: white;
  background-color: var(--main-color);
  border: var(--main-color);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 5px;

  display: block;
  margin: auto;
}
#hidden_iframe{
  display: none;
}
.hiddenUploader{
  display: none;
  width: 0.1px;
  height: 0.1px;
}

#imageInputLabel{
  background-color: var(--main-color);
  color: white;
  width: fit-content;
  display: block;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  cursor: pointer;
  font-family: BalooTamma2;
  font-weight: bold;
  margin: auto;
}
#resumeUploaderFileName{
  text-align: center;
  color: var(--text-color);
  font-family: "Sugoe UI";
  font-weight: bold;
}
/* volunteerPage end */
/* donatePage*/
#donateLeft img{
  width: 100%;
}
#donateLeft p{
  font-family: "Sugoe UI";
  color: var(--text-color);
  font-size: 18px;
}
#Volform h6{
  text-align: center;
  font-family: "Sugoe UI";
  color: var(--text-color);
}
#donateAmountDiv{
  width: 210px;
  margin:auto;
}
#donateAmountDiv button{
  width: 40px;
  background-color: var(--main-color);
  color:white;
  height: 40px;
  border-radius: 5px;
}
#donateAmountInput{
  width: 120px;
  text-align: center;
  height: 40px;
  margin-right: 5px;
  margin-left: 5px;
  vertical-align: bottom;
  border-radius: 5px;
  border: var(--main-color);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  transition-timing-function:ease;
  -webkit-transition: border-width 0.5s; /* For Safari 3.1 to 6.0 */
  transition: border-width 0.5s;
}
#donateAmountInput:focus{
  border-width: 2px 2px 2px 2px;
  outline: none;
  transition-timing-function:ease;
  -webkit-transition: border-width 0.5s; /* For Safari 3.1 to 6.0 */
  transition: border-width 0.5s;
}
.donateFormsCheck{
  margin: auto;
}
.form-check-label{
  font-size: 25px;
  font-weight: bold;
}
.classCardExpSelect{
  font-family: "Sugoe UI";
  font-size: 15px;
  margin-bottom: 20px;
  width: 47.5%;
  color: var(--text-color);
  background-color: #F1F1F1;
  border: var(--main-color);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  transition-timing-function:ease;
  -webkit-transition: border-width 0.5s; /* For Safari 3.1 to 6.0 */
  transition: border-width 0.5s;
  border-radius: 5px;
  height: 40px;
  display: inline-block;
  text-align-last: center;
}
.classCardExpSelect:focus{
  border: var(--main-color);
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  outline: 0;
  box-shadow:0 0 0 0 rgba(0,123,255,.25);
  transition-timing-function:ease;
  -webkit-transition: border-width 0.5s; /* For Safari 3.1 to 6.0 */
  transition: border-width 0.5s;
}
#monthExpSelect{
  margin-left: 5%;
}
#monthExpSelectAr{
  margin-right: 5%;
}
.form-check-input{
  margin-right: -1.25rem;
}
/* donatePage end */
/* contactPage*/
#seedInfoDiv h4{
  margin-top: 50px;
  color: var(--main-color);
  font-family: "Sugoe UI";
  font-weight: bold;
  width: fit-content;
}
.contactInfo{
  margin-top: 20px;
}
.contactInfoItem p{
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-family: BalooTamma2;
  color: var(--text-color);
}
.contactInfoItem{
  width: fit-content;
}
.contactInfoItem img{
  width: 30px;
}
#Volform textarea{
    min-height: 100px;
}
/* contactPage end*/
.redText{
  color: red;
}

/* */
.teamItem{
  display: inline-block;
  max-width: 300px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.teamItem img{
  width: 270px;
  height: 270px;
  border-radius: 270px;
  display: block;
  margin: auto;
  transition-timing-function: linear;
  -webkit-transition: filter 2s; /* For Safari 3.1 to 6.0 */
  transition: filter 2s;
  object-fit: cover;
  background-color: white;

}
.teamItem:hover img{
  filter:  brightness(40%);
  transition-timing-function: linear;
  -webkit-transition: filter 2s; /* For Safari 3.1 to 6.0 */
  transition: filter 2s;

}
.teamItem:hover p{
  color:var(--main-color);

}
.teamItem:hover h6{
  color:var(--main-color);

}
.teamItem h6{
  text-align: center;
  font-family: "Sugoe UI";
  font-weight: bold;
  font-size: 18px;
  color: var(--text-color);
  margin-top: 5px;
  margin-bottom: 0px;
}
.teamItem p{
  text-align: center;
  font-family: "Sugoe UI";
  font-weight: bold;
  font-size: 15px;
  color: var(--text-color);
}
/*  */

.youtubeIframe{
  border:0;
}
.ql-editor{
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: fit-content;
}
#tabDetailsModal{
  min-width: 70%;
  min-height: 500px;
}
#articalModal{
  min-width: 95%;
  min-height: 500px;
}
#tabDetailsModal .modal-body ,#tabDetailsModal .modal-header{
  background-color: #efefef;
}
#tabDetailsModal .modal-header{
  border-bottom: 0px;
}
#tabDetailsModal .modal-header .close{
  margin-left: 0px;
}
 #tabDetailsModal .modal-header .modal-title{
  color: var(--main-color);
 }
.myform-check{
  width: fit-content;
  text-align: center;
}
.myform-checkAr{
  width: fit-content;
  text-align: center;
}
.ql-font-sugoeUI {
  font-family: "Sugoe UI";
}
.ql-font-arial {
  font-family: "Arial";
}
.ql-font-sansSerif{
  font-family: "SansSerif";
}
.ql-font-callibri{
  font-family: "Callibri";
}
.ql-font-ayuthaya{
  font-family: "Ayuthaya";
}
.ql-font-banglamn{
  font-family: "Bangla MN";
}
.ql-font-sugoePrint{
  font-family: "Sugoe Print";
}
.ql-font-monaco{
  font-family: "Monaco";
}
.ql-font-BalooTamma2{
  font-family: 'BalooTamma2';
}
#donateMethodText{
  text-align: center;
  font-family: "Sugoe UI";
  color: var(--main-color);
  margin-top: 10px;
}
.sliderImgTxt{
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
}
.slick-center div div {

}
.slick-center div div img{
  width: 90%;
  margin: auto;
  height: 280px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.slick-center div div h3{
  text-align: center;
}
[dir=rtl] .slick-slide {
    float:left;
}
.newsSlideItem{
  cursor: pointer;
}
.visionBack{

 }
.missionBack{

}
.articlsModal {
  display: block;
  width: 1200px;
  padding-left: 0px;
}
.RectabItem{
  display: inline-block;
  margin-bottom: 10px;
}
.RectabItemMobile{

  margin-bottom: 7px !important;
  padding: 0;
  padding-left: 3px;
  padding-right: 3px;
}
.RectabItemMobile img{

  width:100% !important;
  object-fit: cover;
  height: 200px !important;
}
.RectabItemMobile p{
  text-align: center;
  font-family: BalooTamma2;
  font-weight: bold;
  font-size: 14px !important;
  color: #FFF;
  left: 10px !important;
  position: absolute;
  bottom: 20px;
}
.RectabItem img{
  width: 350px;
  height: 350px;
  display: block;
  margin: auto;
  transition-timing-function: linear;
  -webkit-transition: filter 2s; /* For Safari 3.1 to 6.0 */
  transition: filter 2s;
  object-fit: cover;
  cursor: pointer;

}
.RectabItem:hover img{
  filter:  brightness(40%);
  transition-timing-function: linear;
  -webkit-transition: filter 2s; /* For Safari 3.1 to 6.0 */
  transition: filter 2s;

}
.RectabItem:hover p{
  color:var(--main-color);

}
.RectabItem p{
  text-align: center;
  font-family: BalooTamma2;
  font-weight: bold;
  font-size: 18px;
  color: #FFF;
  left: 15px;
  width: 350px;
  position: absolute;
  bottom: 20px;
}
.volAndDivSection{
  height: 450px;
  width: 100%;
  margin: 0;
}
.volAndDivSectionMobile{
  height: 250px;
  width: 100%;
  margin: 0;
}
.donateSection{
  background-image: url("imgs/donate.jpg");
  background-position: center 50%;
  background-size:cover;
  background-repeat: no-repeat;
}
.volSection{
  background-image: url("imgs/volpic.jpg");
  background-position: center bottom;

}
.volSectionMobile{
  background-position: center 70% !important;
}
.donateSection button,.volSection button{
  width: 200px !important;
  position: absolute;
  top: 65%;
  left: 40%;
}
.donateSectionMobile,.volSectionMobile{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.donateSectionMobile button,.volSectionMobile button{

  width: 120px !important;
  font-size: 13px !important;
  padding: 13px !important;
  position: relative !important;
  display: block !important;
  vertical-align: center;
  top: unset !important;
  left: unset !important;
  margin-top: 70px !important;
}
.missionVisionSection{
  position: absolute;
    top:0;
  z-index: 5;
  width: 100%;
}
.whereWeWorkImageSection{
  height: 350px;
  overflow: hidden;
  margin: 0;
  position: relative;
}
.ourStorySection{
  height: 550px;
  overflow: hidden;
  margin: 0;
  position: relative;
}
.whereWeWorkImageSectionBack{
  background-image: url("imgs/seedsMap.png");
  background-position:center 35%;
  background-size: cover;

}
.whereWeWorkImageSection{
  background-image: url("imgs/seedsMapAr.png");
  background-position:center 35%;
  background-size: cover;
}
.section2Mobile{
  height: 200px !important;
}

.footerCentry{
  font-family: BalooTamma2;
}
.newslayoutText{
  max-height: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sideTitles{
  width: 95%;
  color: white !important;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 3px solid #72B630;
  cursor: pointer;
  overflow: hidden;
}
.sideTitles  *{
  color: white !important;
  text-align: left !important;
  font-size: 16px !important;
  font-weight: bolder !important;
  height: 50px;
  pointer-events: none;

}
.sideTitlesAr{
  width: 95%;
  height: 75px;
  padding-top: 10px;

  margin-left: auto;
  margin-right: auto;
  border-bottom: 3px solid #72B630;
  cursor: pointer;
}
.sideTitlesAr  *{
  color: white !important;
  text-align: right !important;
  font-size: 16px !important;
  font-weight: bolder !important;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;

}
.sideTitlesHeader{
    font-size: 25px;
  font-family: BalooTamma2;
  color: #fff;
  width: 95%;
  margin: auto;
  font-weight: bolder;
}
.galleryImgDiv{
  width: 16.66666666666667%;
  display: inline-block;
  padding-left: 5px;
  transition-timing-function: linear;
  -webkit-transition: width  0.5s; /* For Safari 3.1 to 6.0 */
  transition: width 0.5s;
}
.galleryImgDivSelected{
  width: 100%;
  display: inline-block;
  padding-left: 5px;
  transition-timing-function: linear;
  -webkit-transition: width  0.5s; /* For Safari 3.1 to 6.0 */
  transition: width 0.5s;
}
.gallery{
  position: relative;
}
#footerTitleMobile{
  color: #fff;
  font-family: BalooTamma2;
}
.RectabItemRow{
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 !important;
}
.footerCover{
  height: 300px;
}
.articalsDivAr{
  margin-right: 30% !important;
}
.sideTitlesHeaderDivAr{
  right: 0;
}
.sideTitlesHeaderDiv{
  left: 0;
}
.areaText{
  font-family: BalooTamma2;

  font-weight: bold;
  font-size: 18px;
  color: #2B235E;
  width: fit-content;
  text-align: center;
  margin: auto;
  text-decoration: underline;
  margin-top: 30px;
}
.counryNameText{
  font-family: BalooTamma2 !important;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px !important;
  color: #9C2062 !important;
  text-align: center !important;
  width:100%;
  height: 40px;
  overflow: hidden !important;
}
.counryNameText *{
  font-family: BalooTamma2 !important;
  cursor: pointer;
  font-weight: bold !important;;
  font-size: 17px !important;
  color: #9C2062 !important;
  text-align: center !important;
  overflow: hidden !important;
}
.countriesBack{
  height: 250px;
  overflow: hidden;
  margin: 0;
  position: relative;
}
.countriesBackImg{


  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  margin: 0!important;
  margin-bottom: 50px !important;
}
.whereWeWorkImageContent{
  position: absolute;
  top:25%;
  left: 4%;
}
.whereWeWorkImageContentMobile{
  position: absolute;
  top:17%;
  left: 4%;
}
.whereWeWorkImageContentMobileAr{
  position: absolute;
  top:17%;
  right: 4%;
}
.whereWeWorkImageContentAr{
  position: absolute;
  top:25%;
  right: 4%;
  direction: rtl;
}
.whereWeWorkImageContentMobile p{
  font-size: 17px;
  font-family: "BalooTamma2";
  height: 20px;
  color: #2B235E;
}
.whereWeWorkImageContent p{
  font-size: 30px;
  font-family: "BalooTamma2";
  height: 20px;
  color: #2B235E;
  font-weight: bold;
}
.whereWeWorkImageContentAr p{
  font-size: 30px;
  font-family: "Segoe UI";
  height: 20px;
  color: #2B235E;
  text-align: right;
}
.whereWeWorkImageContentMobileAr p{
  font-size: 17px;
  font-family: "Segoe UI";
  height: 20px;
  color: #2B235E;
  text-align: right;
}
.whereWeWorkImageContent button,.whereWeWorkImageContentAr button{
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;

  border: 0;
  background-color: #72B630;
}
.whereWeWorkImageContentMobile button,.whereWeWorkImageContentMobileAr button{
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 100px;
  border: 0;
  background-color: #72B630;
}
.whereWeWorkImageContentAr button,.whereWeWorkImageContentMobileAr button{
  float: right;
}
.whereWeWorkButton{
  font-size: 25px !important;
  color: #2B235E;
  height: 17px;
}
.homeNewsText {
  font-family: BalooTamma2 !important;
  font-size: 16px !important;
  color: black !important;
  text-align: center !important;
}
.homeNewsText *{
  font-family: BalooTamma2 !important;
  font-size: 16px !important;
  color: #2B235E !important;
  text-align: center !important;
}
.objectFitNews{
  object-fit: fill !important;
}
.learnMoreText{
  font-family: BalooTamma2 !important;
  font-size: 22px !important;
  font-weight: bold;
  color: white !important;
  margin-top: 10px;
  cursor: pointer;
}
.volFieldText{
  font-size: 14px;
  font-family: BalooTamma2;
  font-weight: bold;
  margin: 0;
  color:#2B235E ;
}
.volFieldTextAr{
  font-size: 14px;
  font-family: BalooTamma2;
  font-weight: bold;
  margin: 0;
  text-align: right;
  color:#2B235E ;
}
.volSuccessMSG{
  font-size: 22px;
  font-family: BalooTamma2;
  margin-top: 20px;
  text-align: center;
}
.splashText{

  font-family: BalooTamma2;
  font-weight: bold;
  font-size: 20px;
  color:#2B235E ;
  margin-top: 15px;
}
.newsMenu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.newsMenu {
  -ms-overflow-style: none;
}
.donateAmountInput{

  width: 100%;
  //max-width: 200px;
  height: 40px;
  border: var(--main-color);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  font-family: "BalooTamma2";
  font-size: 15px;
  text-align: left;
  color: var(--text-color);
  border-radius: 5px;
  padding-left: 5px;
  text-align: center;
}
.donateAmountLabel{
  font-family: "BalooTamma2";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 100%;

}
.donateInputWrap{
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.incButton,.decButton{
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
}
.incButton{

}
.decButton{

}
.donate_thanks{
  font-size: 22px;
  font-weight: 700;
  font-family: "BalooTamma2";
  text-align: center;

}
